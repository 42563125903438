*, ::before, ::after {
    /* or cover? */
    background-size: contain;
    background-position: 50% 50%;
}

textarea {
    vertical-align: baseline;
    vertical-align: -webkit-baseline-middle;
    xdisplay: inline-table; /* chrome makes align with the first line, but unresizable */
}
[type=number] {
    text-align: right;
}
dialog {
    /*
        or "none"? Prevent scroll chaning. -safari. ok?
        overscroll-behavior does not inherit!
    */
    overscroll-behavior: contain;
}

script, style { /* if displaying script or javascript */
    white-space: pre;
    font-family: monospace;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

legend { /* make padding font dependent */
    padding-left: .3em;
    padding-right: .3em;
}